// How long venues are cached for. Prevents API usage if same area is search and viewed.
//const CACHE_DURATION_MS = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
const CACHE_DURATION_MS = 30 * 24 * 60 * 60 * 1000; // 30 days  in milliseconds
// const CACHE_DURATION_MS = 5; // 5ms


function checkOpenStatusFromPeriods(periods) {
    if (!Array.isArray(periods)) {
        console.log('Invalid periods data:', periods);
        return null;
    }

    const now = new Date();
    const currentDay = now.getDay(); // 0-6, Sunday is 0
    const currentTime = now.getHours() * 60 + now.getMinutes(); // minutes since midnight

    //console.log('Current day:', currentDay, 'Current time (minutes):', currentTime);

    const todayPeriod = periods.find(period => period.open ? .day === currentDay);
    if (!todayPeriod) {
        //console.log('No period found for today');
        return false;
    }

    //console.log('Today\'s period:', todayPeriod);

    // Parse opening time
    const openTime = parseInt(todayPeriod.open.time.substr(0, 2)) * 60 +
        parseInt(todayPeriod.open.time.substr(2, 2));

    // Parse closing time
    let closeTime = parseInt(todayPeriod.close.time.substr(0, 2)) * 60 +
        parseInt(todayPeriod.close.time.substr(2, 2));

    //console.log('Open time (minutes):', openTime, 'Close time (minutes):', closeTime);

    // Handle after-midnight closing times
    if (closeTime < openTime) {
        closeTime += 24 * 60; // Add 24 hours
        if (currentTime < openTime) {
            // If we're before opening time, check against the extended close time
            return currentTime + (24 * 60) <= closeTime;
        }
    }

    const isOpen = currentTime >= openTime && currentTime <= closeTime;
    //console.log('Venue is:', isOpen ? 'OPEN' : 'CLOSED');
    return isOpen;
}

// Function to check if cached data is still valid
function isCacheValid(timestamp) {
    const currentTime = new Date().getTime();
    return currentTime - timestamp < CACHE_DURATION_MS;
}

// Add this function to get cache duration in seconds (for PHP use)
function getCacheDurationInSeconds() {
    return CACHE_DURATION_MS / 1000;
}

// utils.js
// fix dodgy text characters
function decodeHTMLEntities(text) {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    return textArea.value;
}

// Function to create and update markers with event information
function createAdvancedMarker(markerData) {
    if (!map) {
        console.error("Map is not initialized.");
        return;
    }

    let content = document.createElement('div');

    if (markerData.type === 'venue') {
        // Initialize marker with base venue class
        let markerClass = 'venue-marker';

        // Add vibe-related classes if applicable
        const vibeScore = markerData.vibeScore || 0;
        if (markerData.likes > 0 || markerData.dislikes > 0) {
            if (vibeScore > 0) markerClass += ' positive';
            else if (vibeScore < 0) markerClass += ' negative';
            else markerClass += ' neutral';
        }

        content.className = markerClass;
        content.innerHTML = `<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 39">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M27.646 6.946c-1.48-2.305-3.585-4.22-6.053-5.413C19.02.285 16.107-.245 13.267.104A15.203 15.203 0 0 0 5.509 3.39C3.365 5.123 1.75 7.467.835 10.059a14.987 14.987 0 0 0-.086 9.61c.606 1.83 1.666 3.5 2.736 5.085 1.341 1.991 2.802 3.891 4.301 5.763a160.787 160.787 0 0 0 4.106 4.912 133.956 133.956 0 0 0 2.38 2.68c.018.021.035.041.055.06.338.368 1.012.368 1.35 0 .466-.508.922-1.021 1.378-1.534l.007-.008a180.275 180.275 0 0 0 3.309-3.852 130.871 130.871 0 0 0 4.067-5.118c1.203-1.6 2.373-3.237 3.385-4.965.434-.745.826-1.504 1.16-2.301.55-1.313.836-2.731.96-4.144.038-.416.057-.836.057-1.256v-.01c-.014-2.826-.826-5.647-2.354-8.035ZM15.059 19.863a4.806 4.806 0 1 0 0-9.611 4.806 4.806 0 0 0 0 9.611Z"
                    fill="url(#default)" />
                <defs>
                <linearGradient id="default" x1="15" y1="9.131" x2="15" y2="58.949" gradientUnits="userSpaceOnUse"><stop stop-color="#01BBFF"/><stop offset="1" stop-color="#E503A3"/></linearGradient>
                    <linearGradient id="neutral" x1="15" y1="9.13073" x2="15" y2="38" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FFD600" />
                        <stop offset="1" stop-color="#FFA800" />
                    </linearGradient>
                    <linearGradient id="negative" x1="15" y1="9.13073" x2="15" y2="58.9493"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#EA3D56" />
                        <stop offset="1" stop-color="#8B091C" />
                    </linearGradient>
                    <linearGradient id="positive" x1="15" y1="9.13073" x2="15" y2="58.9493"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#71E409" />
                        <stop offset="1" stop-color="#254E00" />
                    </linearGradient>
                </defs>
            </svg><div class="pulse"></div>`;
    }

    const marker = new google.maps.marker.AdvancedMarkerElement({
        position: { lat: parseFloat(markerData.latitude), lng: parseFloat(markerData.longitude) },
        map: map,
        content: content,
        title: `Marker ID: ${markerData.place_id || markerData.id}`,
    });

    marker.metadata = {
        type: markerData.type,
        id: markerData.id,
        place_id: markerData.place_id || null,
        vibeScore: markerData.vibeScore || 0,
        likes: markerData.likes || 0,
        dislikes: markerData.dislikes || 0,
        eventsChecked: false // Add this flag
    };

    // Check for events after marker is fully created
    if (!marker.metadata.eventsChecked) {
        checkForEvents(marker);
        marker.metadata.eventsChecked = true;
    }

    marker.addListener('gmp-click', handleMarkerClick);
    markers.push(marker);
    // Add class monitoring
    //monitorMarkerClasses(marker);
    return marker;
}



function showVenueSheet() {
    const venueSheet = document.getElementById('venueSheet');
    if (venueSheet) {
        showSheet(venueSheet);
    }
}

// Modify checkForEvents to use global events when possible
function checkForEvents(marker) {
    if (!marker.metadata ? .place_id || marker.metadata.eventsChecked) {
        return;
    }

    const markerPlaceId = marker.metadata.place_id;

    // First check if we have global events
    if (window.globalEvents && Array.isArray(window.globalEvents)) {
        const today = new Date().toISOString().split('T')[0];
        const todayEvents = window.globalEvents.filter(event =>
            event.date_from === today &&
            event.place_id === markerPlaceId
        );

        if (todayEvents.length > 0) {
            updateMarkerWithEventClasses(marker, todayEvents);
            marker.metadata.eventsChecked = true;
            return; // Exit early if we found events in global cache
        }
    }

    // Only fetch individual events if not found in global cache
    $.ajax({
        type: 'GET',
        url: 'get_events.php',
        data: { place_ids: JSON.stringify([markerPlaceId]) },
        dataType: 'json',
        success: function(eventResponse) {
            if (eventResponse ? .events) {
                const today = new Date().toISOString().split('T')[0];
                const todayEvents = eventResponse.events.filter(event =>
                    event.date_from === today &&
                    event.place_id === markerPlaceId
                );

                if (todayEvents.length > 0) {
                    updateMarkerWithEventClasses(marker, todayEvents);
                }
            }
            marker.metadata.eventsChecked = true;
        },
        error: function(xhr, status, error) {
            console.error('Error fetching events for marker:', error);
            marker.metadata.eventsChecked = true;
        }
    });
}

// Define class categories and their possible values
const MARKER_CLASSES = {
    BASE: ['venue-marker'],
    VIBE: ['positive', 'negative', 'neutral'],
    EVENT: ['event'],
    CATEGORY: [] // Will be populated with event categories dynamically
};

// Extract marker class update logic to a separate function
function updateMarkerWithEventClasses(marker, events) {
    if (!marker.content) return;

    // Get current classes and filter out any existing event-related classes
    const currentClasses = marker.content.className.split(' ');
    const baseClasses = currentClasses.filter(className =>
        className === 'venue-marker' || ['positive', 'negative', 'neutral'].includes(className)
    );

    // Add event class
    const newClasses = [...baseClasses, 'event'];

    // Add category class if available
    const eventWithCategory = events.find(event => event.category);
    if (eventWithCategory ? .category) {
        const categoryClass = eventWithCategory.category.toLowerCase().replace(/[^a-z0-9]/g, '_');
        newClasses.push(categoryClass);
    }

    // Update marker classes
    marker.content.className = newClasses.join(' ');

    // Store events in marker metadata
    marker.metadata.events = events;
    marker.metadata.eventsChecked = true;
}

function updateMarkerClasses(marker, newClasses, source = '') {
    if (!marker || !marker.content) {
        console.warn('Invalid marker or marker content');
        return;
    }

    // Store the current state in marker's metadata
    if (!marker.metadata) marker.metadata = {};
    if (!marker.metadata.classes) marker.metadata.classes = new Set(['venue-marker']);

    // console.log('Updating marker classes:', {
    //     timestamp: new Date().toISOString(),
    //     source: source,
    //     placeId: marker.metadata ? .place_id,
    //     currentClasses: Array.from(marker.metadata.classes),
    //     newClasses: newClasses
    // });

    // Add new classes to the stored set
    newClasses.forEach(cls => marker.metadata.classes.add(cls));

    // Apply all stored classes to the marker
    marker.content.className = Array.from(marker.metadata.classes).join(' ');

    // console.log('Final marker state:', {
    //     placeId: marker.metadata ? .place_id,
    //     classes: Array.from(marker.metadata.classes),
    //     source: source
    // });
}

// Function to remove specific classes from a marker
function removeMarkerClasses(marker, classesToRemove) {
    if (!marker ? .metadata ? .classes) return;

    classesToRemove.forEach(cls => marker.metadata.classes.delete(cls));
    marker.content.className = Array.from(marker.metadata.classes).join(' ');
}

// Function to get current marker classes
function getMarkerClasses(marker) {
    return marker ? .metadata ? .classes ? Array.from(marker.metadata.classes) : [];
}

// Function to add a generic marker, used for specific vibe markers
function addMarker(markerData) {
    let iconUrl = 'img/venue-marker.svg'; // Default to venue marker

    if (markerData.liked === 1) {
        iconUrl = 'img/liked-marker.svg';
    } else if (markerData.liked === 0) {
        iconUrl = 'img/unliked-marker.svg';
    }

    // console.log(`Adding marker for ${markerData.name || 'Unknown'} with icon: ${iconUrl}`);

    // Create a content div for the advanced marker
    const content = document.createElement('div');
    content.innerHTML = `<img src="${iconUrl}" style="width:30px; height:30px;">`;

    // Create an AdvancedMarkerElement
    const marker = new google.maps.marker.AdvancedMarkerElement({
        map: map,
        position: { lat: parseFloat(markerData.latitude), lng: parseFloat(markerData.longitude) },
        content: content,
        title: `Marker ID: ${markerData.id}`,
    });

    // Store metadata in marker for reference
    marker.metadata = {
        id: markerData.id,
        name: markerData.name,
        liked: markerData.liked,
    };

    // Add a click listener to show venue details
    marker.addListener('gmp-click', function() {
        map.setCenter(marker.position);
        fetchMarkerDetails(marker.metadata.id, marker);
    });

    markers.push(marker);
}


// Debounce function for the slider
function debounce(func, wait) {
    let timeout;
    return function(...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
    };
}

// Modified fetchGoogleVenueDetails function
function fetchGoogleVenueDetails(placeId, marker, shouldSave = true) {
    const service = new google.maps.places.PlacesService(map);

    service.getDetails({
        placeId: placeId,
        fields: ['name', 'formatted_address', 'geometry', 'photos', 'opening_hours', 'rating']
    }, (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
            //console.log('Raw Google place data:', place);

            // Format opening hours data
            let formattedOpeningHours = null;
            let periodsData = null;

            if (place.opening_hours) {
                // Get weekday text for readable format
                if (place.opening_hours.weekday_text) {
                    formattedOpeningHours = place.opening_hours.weekday_text.join(', ');
                }

                // Get periods data if available
                if (place.opening_hours.periods) {
                    periodsData = JSON.stringify(place.opening_hours.periods);
                }
            }

            // Process photos
            let photoUrls = [];
            if (place.photos && place.photos.length > 0) {
                photoUrls = place.photos.slice(0, PHOTO_LIMIT).map(photo => {
                    try {
                        return photo.getUrl({ maxWidth: 400 });
                    } catch (e) {
                        //console.error('Error getting photo URL:', e);
                        return null;
                    }
                }).filter(url => url !== null);
            }
            //console.log('Processed photo URLs:', photoUrls);

            const venueDetails = {
                place_id: placeId,
                name: place.name,
                address: place.formatted_address || 'Address not available',
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng(),
                photo_urls: JSON.stringify(photoUrls),
                opening_hours: formattedOpeningHours,
                opening_hours_periods: periodsData,
                google_rating: place.rating || null
            };

            if (shouldSave) {
                saveVenueToDatabase(venueDetails);
            }

            // For display, structure the data correctly
            const displayDetails = {
                ...venueDetails,
                photo_urls: photoUrls, // Using the photoUrls array directly for display
                opening_hours: {
                    opening_hours: formattedOpeningHours,
                    opening_hours_periods: periodsData
                }
            };

            displayVenueDetails(displayDetails);
            fetchVibes(placeId);
            showVenueSheet();
        } else {
            console.error('Failed to fetch venue details:', status);
        }
    });
}

// Modified saveVenueToDatabase function
function saveVenueToDatabase(venueDetails) {
    console.log('Preparing to save venue details:', venueDetails);

    // Ensure photo_urls is properly formatted
    let photoUrlsToSave = venueDetails.photo_urls;
    if (Array.isArray(venueDetails.photo_urls)) {
        photoUrlsToSave = JSON.stringify(venueDetails.photo_urls);
    } else if (typeof venueDetails.photo_urls === 'string') {
        try {
            // Validate it's a proper JSON string
            JSON.parse(venueDetails.photo_urls);
            photoUrlsToSave = venueDetails.photo_urls;
        } catch (e) {
            console.error('Invalid photo_urls JSON string:', e);
            photoUrlsToSave = '[]';
        }
    } else {
        photoUrlsToSave = '[]';
    }

    const dataToSave = {
        ...venueDetails,
        photo_urls: photoUrlsToSave,
        opening_hours: venueDetails.opening_hours || '',
        opening_hours_periods: venueDetails.opening_hours_periods || null
    };

    console.log('Sending to server:', dataToSave);

    $.ajax({
        type: 'POST',
        url: 'save_venue_details.php',
        data: dataToSave,
        success: function(response) {
            //console.log('Server response:', response);
            try {
                const parsedResponse = typeof response === 'string' ?
                    JSON.parse(response) : response;

                if (parsedResponse.success) {
                    console.log('Venue details saved successfully:', parsedResponse.debug);
                } else {
                    console.error('Error saving venue details:', parsedResponse.error);
                }
            } catch (e) {
                console.error('Error parsing server response:', e);
            }
        },
        error: function(xhr, status, error) {
            console.error('AJAX error saving venue details:', {
                status: status,
                error: error,
                response: xhr.responseText
            });
        }
    });
}

// function fetchFromGoogleAPI(placeId, shouldSave) {
//     const service = new google.maps.places.PlacesService(map);

//     service.getDetails({ placeId: placeId }, (place, status) => {
//         if (status === google.maps.places.PlacesServiceStatus.OK) {
//             console.log('Venue details from Google API:', place);

//             // Format opening hours before creating venueDetails
//             const formattedHours = place.opening_hours ?
//                 OpeningHoursHandler.formatGoogleOpeningHours(place.opening_hours) : null;

//             const venueDetails = {
//                 place_id: placeId,
//                 name: place.name,
//                 address: place.formatted_address || 'Address not available',
//                 latitude: place.geometry.location.lat(),
//                 longitude: place.geometry.location.lng(),
//                 photo_urls: place.photos ?
//                     place.photos.slice(0, PHOTO_LIMIT).map(photo => photo.getUrl({ maxWidth: 400 })) :
//                     [],
//                 opening_hours: formattedHours, // Use the formatted hours
//                 google_rating: place.rating || null
//             };

//             if (shouldSave) {
//                 saveVenueToDatabase(venueDetails);
//             }

//             displayVenueDetails(venueDetails);
//             showVenueSheet();
//         } else {
//             console.error('Failed to fetch venue details:', status);
//         }
//     });
// }

// Opening hours handler to ensure consistent formatting between Google API and cached data
// Enhanced OpeningHoursHandler class
// Enhanced OpeningHoursHandler class with better error handling
class OpeningHoursHandler {
    static formatGoogleOpeningHours(googleOpeningHours) {
        if (!googleOpeningHours) return null;

        try {
            // For direct Google Places API response
            if (typeof googleOpeningHours === 'object' && googleOpeningHours.periods) {
                return {
                    formatted: googleOpeningHours.weekday_text ? googleOpeningHours.weekday_text.join(', ') : '',
                    weekday_text: googleOpeningHours.weekday_text || [],
                    periods: googleOpeningHours.periods || [],
                    isOpen: () => {
                        try {
                            return googleOpeningHours.isOpen ? googleOpeningHours.isOpen() : null;
                        } catch (e) {
                            console.warn('Error checking isOpen status:', e);
                            return null;
                        }
                    }
                };
            }

            // For cached/stored opening hours string format
            if (typeof googleOpeningHours === 'string') {
                const weekday_text = googleOpeningHours.split(', ').filter(text => text.trim());
                return {
                    formatted: googleOpeningHours,
                    weekday_text: weekday_text,
                    periods: [],
                    isOpen: () => this.checkIfOpenNow(weekday_text)
                };
            }

            return null;
        } catch (error) {
            console.error('Error formatting opening hours:', error);
            return null;
        }
    }

    static checkIfOpenNow(weekdayText) {
        if (!Array.isArray(weekdayText) || weekdayText.length === 0) return false;

        try {
            const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            const now = new Date();
            const currentDay = days[now.getDay()];
            const currentHour = now.getHours();
            const currentMinute = now.getMinutes();

            // Find today's hours
            const todayHours = weekdayText.find(text => text.startsWith(currentDay));
            if (!todayHours) return false;

            // Check if closed today
            if (todayHours.includes('Closed')) return false;

            // Extract opening hours using regex
            const timeRegex = /(\d{1,2}):(\d{2})\s*(AM|PM)/gi;
            const times = todayHours.match(timeRegex);
            if (!times || times.length !== 2) return false;

            const [openTime, closeTime] = times;
            const [openHour, openMinute] = this.convertTo24Hour(openTime);
            const [closeHour, closeMinute] = this.convertTo24Hour(closeTime);

            // Create comparable values (minutes since midnight)
            const currentMinutes = currentHour * 60 + currentMinute;
            const openMinutes = openHour * 60 + openMinute;
            let closeMinutes = closeHour * 60 + closeMinute;

            // Handle after-midnight closing times
            if (closeMinutes < openMinutes) {
                closeMinutes += 24 * 60;
                if (currentMinutes < openMinutes) {
                    return currentMinutes + (24 * 60) <= closeMinutes;
                }
            }

            return currentMinutes >= openMinutes && currentMinutes <= closeMinutes;
        } catch (error) {
            console.warn('Error checking if open now:', error);
            return null;
        }
    }

    static convertTo24Hour(timeStr) {
        if (!timeStr) return [0, 0];

        const match = timeStr.match(/(\d{1,2}):(\d{2})\s*(AM|PM)/i);
        if (!match) return [0, 0];

        const [_, hours, minutes, period] = match;
        let hour = parseInt(hours, 10);
        const minute = parseInt(minutes, 10);

        if (period.toUpperCase() === 'PM' && hour !== 12) {
            hour += 12;
        } else if (period.toUpperCase() === 'AM' && hour === 12) {
            hour = 0;
        }

        return [hour, minute];
    }

    // Update the updateVenueOpenStatus method
    static updateVenueOpenStatus(venueElement, openingHours) {
        if (!venueElement || !openingHours) return;

        const statusElement = venueElement.querySelector('.open-now-status');
        if (!statusElement) return;

        try {
            let isOpen = null;

            // Handle direct Google Places API response
            if (typeof openingHours.isOpen === 'function') {
                isOpen = openingHours.isOpen();
                console.log('Using Google isOpen function:', isOpen);
            }
            // Handle cached venues with periods
            else if (openingHours.opening_hours_periods) {
                let periods = openingHours.opening_hours_periods;
                if (typeof periods === 'string') {
                    periods = JSON.parse(periods);
                }
                isOpen = this.checkOpenStatusFromPeriods(periods);
                console.log('Using cached periods data:', isOpen);
            }
            // Handle cached venues with weekday text
            else if (typeof openingHours.opening_hours === 'string') {
                const weekdayText = openingHours.opening_hours.split(', ');
                isOpen = this.checkIfOpenNow(weekdayText);
                console.log('Using weekday text:', isOpen);
            }

            // Update the UI
            if (isOpen === null) {
                statusElement.textContent = 'Status unknown';
                statusElement.className = 'open-now-status unknown';
            } else {
                statusElement.textContent = isOpen ? 'Open Now' : 'Closed';
                statusElement.className = `open-now-status ${isOpen ? 'open' : 'closed'}`;
            }
            venueElement.dataset.openNow = isOpen === null ? 'unknown' : String(isOpen);

        } catch (error) {
            console.error('Error updating venue open status:', error);
            statusElement.textContent = 'Status unknown';
            statusElement.className = 'open-now-status unknown';
            venueElement.dataset.openNow = 'unknown';
        }
    }

    // For displaying readable hours
    static getOpeningHoursDisplay(openingHours) {
        if (!openingHours) return 'Opening hours not available';

        // Use the readable format if available
        if (openingHours.opening_hours) {
            return openingHours.opening_hours.split(', ').join('<br>');
        }
        // Fallback to weekday_text from Google API
        else if (openingHours.weekday_text) {
            return openingHours.weekday_text.join('<br>');
        }

        return 'Opening hours not available';
    }

    static checkOpenStatusFromPeriods(periods) {
        if (!Array.isArray(periods)) return null;

        const now = new Date();
        const currentDay = now.getDay();
        const currentTime = now.getHours() * 60 + now.getMinutes();

        const todayPeriod = periods.find(period => period.open.day === currentDay);
        if (!todayPeriod) return false;

        const openMinutes = parseInt(todayPeriod.open.time.slice(0, 2)) * 60 +
            parseInt(todayPeriod.open.time.slice(2));
        let closeMinutes = parseInt(todayPeriod.close.time.slice(0, 2)) * 60 +
            parseInt(todayPeriod.close.time.slice(2));

        if (closeMinutes < openMinutes) {
            closeMinutes += 24 * 60;
        }

        return currentTime >= openMinutes && currentTime < closeMinutes;
    }
}

// Helper function to process photo URL
function processPhotoUrl(url) {
    if (!url) return 'img/placeholder.png';

    // If it's a Google Maps URL or any other full URL, use it directly
    if (url.startsWith('http')) {
        return url;
    }

    // If it's a local path, prepend 'venues/'
    return `venues/${url}`;
}


function displayVenueDetails(data) {
    if (!data) {
        console.error('Error: No data provided to displayVenueDetails');
        return;
    }

    const venueSheet = document.getElementById('venueSheet');
    if (venueSheet) {
        // Reset the sheet's state

        // 1. Clear any checked radio buttons
        venueSheet.querySelectorAll('input[name="likeStatus"]').forEach(radio => {
            radio.checked = false;
        });

        // 2. Reset scroll position
        venueSheet.querySelector('.bot-modal-content') ? .scrollTo(0, 0);

        // 3. Reset horizontal scroll position of image gallery
        const imageGallery = document.getElementById('venue-image-gallery');
        if (imageGallery) {
            imageGallery.scrollTo({
                left: 0,
                behavior: 'instant' // Use 'instant' instead of 'smooth' to avoid animation
            });
        }

        // 4. Reset any accordion states if they exist
        venueSheet.querySelectorAll('.accordion .trigger.active').forEach(trigger => {
            trigger.classList.remove('active');
            const content = document.getElementById(trigger.getAttribute('data-target'));
            if (content) {
                content.classList.remove('show');
                content.style.maxHeight = null;
            }
        });

        // Set place_id for the new venue
        venueSheet.setAttribute('data-place-id', data.place_id);
    }

    // Update venue name
    const venueNameElement = document.querySelector('#venue-sheet-venue-name');
    if (venueNameElement) {
        const decodedName = decodeHTMLEntities(data.name || 'Not available');
        venueNameElement.textContent = decodedName;
    }

    // Update Google rating
    const googleRatingElement = document.getElementById('google-rating');
    if (googleRatingElement) {
        googleRatingElement.textContent = data.google_rating || 'Not available';
    }

    // Calculate and update distance
    const distanceElement = document.querySelector('#venue-details .distance-display');
    if (distanceElement && userLocation && data.latitude && data.longitude) {
        const from = new google.maps.LatLng(userLocation.lat, userLocation.lng);
        const to = new google.maps.LatLng(parseFloat(data.latitude), parseFloat(data.longitude));
        const distance = google.maps.geometry.spherical.computeDistanceBetween(from, to) / 1000; // Convert to kilometers
        distanceElement.textContent = `${distance.toFixed(2)} km`;
    } else if (distanceElement) {
        distanceElement.textContent = '-- km';
    }

    // Handle opening hours
    const openingHoursElement = document.getElementById('opening-hours');
    const statusElement = document.querySelector('.trigger .open-now-status');

    let isOpen = null;

    // Case 1: Direct Google Places API response (for new, uncached venues)
    if (data.opening_hours && typeof data.opening_hours.isOpen === 'function') {
        console.log('Using Google Places API opening hours');
        if (openingHoursElement) {
            openingHoursElement.innerHTML = data.opening_hours.weekday_text.join('<br>');
        }

        try {
            isOpen = data.opening_hours.isOpen();
            if (statusElement) {
                statusElement.textContent = isOpen ? 'Open' : 'Closed';
                statusElement.className = `open-now-status ${isOpen ? 'open' : 'closed'}`;
            }
        } catch (e) {
            console.error('Error calling Google isOpen():', e);
            if (statusElement) {
                statusElement.textContent = 'Opening hours';
                statusElement.className = 'open-now-status unknown';
            }
        }
    }
    // Case 2: Cached venue with periods data
    else if (data.opening_hours_periods) {
        console.log('Using cached opening hours periods');
        let periods = data.opening_hours_periods;

        if (typeof periods === 'string') {
            try {
                periods = JSON.parse(periods);
            } catch (e) {
                console.error('Error parsing opening_hours_periods:', e);
                periods = null;
            }
        }

        if (Array.isArray(periods)) {
            if (openingHoursElement) {
                const formattedHours = periods.map(period => {
                    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                    const openTime = `${period.open.hours}:${period.open.minutes.toString().padStart(2, '0')}`;
                    const closeTime = `${period.close.hours}:${period.close.minutes.toString().padStart(2, '0')}`;
                    return `${days[period.open.day]}: ${openTime} - ${closeTime}`;
                }).join('<br>');
                openingHoursElement.innerHTML = formattedHours;
            }

            isOpen = checkOpenStatusFromPeriods(periods);
            if (statusElement) {
                if (isOpen !== null) {
                    statusElement.textContent = isOpen ? 'Open' : 'Closed';
                    statusElement.className = `open-now-status ${isOpen ? 'open' : 'closed'}`;
                } else {
                    statusElement.textContent = 'Opening hours';
                    statusElement.className = 'open-now-status unknown';
                }
            }
        }
    }
    // Case 3: Legacy format or string format
    else if (data.opening_hours && typeof data.opening_hours === 'string') {
        console.log('Using legacy opening hours format');
        if (openingHoursElement) {
            openingHoursElement.innerHTML = data.opening_hours;
        }
        // Try to determine open status from string format
        const weekdayText = data.opening_hours.split(', ');
        isOpen = OpeningHoursHandler.checkIfOpenNow(weekdayText);
        if (statusElement) {
            if (isOpen !== null) {
                statusElement.textContent = isOpen ? 'Open' : 'Closed';
                statusElement.className = `open-now-status ${isOpen ? 'open' : 'closed'}`;
            } else {
                statusElement.textContent = 'Opening hours';
                statusElement.className = 'open-now-status unknown';
            }
        }
    }
    // Case 4: No opening hours data available
    else {
        //console.log('No opening hours data available');
        if (openingHoursElement) {
            openingHoursElement.innerHTML = 'Opening hours not available';
        }
        if (statusElement) {
            statusElement.textContent = 'Opening hours';
            statusElement.className = 'open-now-status unknown';
        }
    }

    // Handle photos
    let photoUrls = [];
    if (data.photo_urls) {
        try {
            if (typeof data.photo_urls === 'string') {
                photoUrls = JSON.parse(data.photo_urls);
            } else if (Array.isArray(data.photo_urls)) {
                photoUrls = data.photo_urls;
            }

            photoUrls = photoUrls.map(url => processPhotoUrl(url));
        } catch (error) {
            console.error('Error processing photo_urls:', error);
            photoUrls = [];
        }
    }

    console.log('Processed photoUrls:', photoUrls);
    updateImageGallery(photoUrls);

    // Fetch events
    const eventInfoElement = document.getElementById('event-info');
    if (eventInfoElement) {
        fetchEventsForVenue(data.place_id, eventInfoElement);
    }
}

// Helper function to check if a file exists
function fileExists(url) {
    var http = new XMLHttpRequest();
    http.open('HEAD', url, false);
    http.send();
    return http.status != 404;
}

function updateImageGallery(photoUrls) {
    const imageGallery = document.getElementById('venue-image-gallery');
    if (!imageGallery) {
        console.error('Image gallery element not found');
        return;
    }

    imageGallery.innerHTML = ''; // Clear existing images

    if (photoUrls && photoUrls.length > 0) {
        photoUrls.forEach(url => {
            const img = document.createElement('img');
            img.src = url;
            img.alt = 'Venue Image';
            img.className = 'gallery-image';
            imageGallery.appendChild(img);
        });
    } else {
        const img = document.createElement('img');
        img.src = 'img/placeholder.png';
        img.alt = 'Placeholder Image';
        img.className = 'gallery-image';
        imageGallery.appendChild(img);
    }

    // Initialize the gallery functionality
    initializeImageGallery('venue-image-gallery');
}

// Declare currentGalleryCleanup at the top of your script or in an appropriate scope
let currentGalleryCleanup = null;


function initializeImageGallery(galleryId) {
    const imageGallery = document.getElementById(galleryId);
    if (!imageGallery) {
        console.error('Image gallery element not found:', galleryId);
        return;
    }

    // Clean up previous gallery if it exists
    if (currentGalleryCleanup) {
        currentGalleryCleanup();
    }

    function createCloseButton() {
        const closeButton = document.createElement('span');
        closeButton.classList.add('close-button');
        closeButton.innerHTML = '&times;';
        return closeButton;
    }

    function handleImageClick(event) {
        const clickedImage = event.target.closest('.gallery-image');
        if (!clickedImage) return;

        //console.log('Image clicked:', clickedImage.src);

        const existingFullSizeContainer = document.querySelector('.full-size-container');

        if (existingFullSizeContainer) {
            //console.log('Closing full-size image');
            closeImage();
        } else {
            //console.log('Opening full-size image');
            openFullSizeImage(clickedImage);
        }
    }

    function openFullSizeImage(image) {
        const fullSizeContainer = document.createElement('div');
        fullSizeContainer.classList.add('full-size-container');

        const fullSizeImage = image.cloneNode(true);
        fullSizeImage.classList.add('full-size');
        fullSizeContainer.appendChild(fullSizeImage);

        const closeButton = createCloseButton();
        fullSizeContainer.appendChild(closeButton);

        document.body.appendChild(fullSizeContainer);

        fullSizeContainer.addEventListener('click', (e) => {
            if (e.target === fullSizeContainer || e.target === fullSizeImage) {
                closeImage();
            }
        });
        closeButton.addEventListener('click', closeImage);
    }

    function closeImage() {
        const fullSizeContainer = document.querySelector('.full-size-container');
        if (fullSizeContainer) {
            document.body.removeChild(fullSizeContainer);
            //console.log('Full-size image closed');
        }
    }

    const galleryClickHandler = (event) => {
        if (event.target.closest('.gallery-image')) {
            handleImageClick(event);
        }
    };

    imageGallery.addEventListener('click', galleryClickHandler);

    // Set up the cleanup function
    currentGalleryCleanup = () => {
        imageGallery.removeEventListener('click', galleryClickHandler);
        closeImage(); // Close any open full-size image
        //console.log('Gallery cleaned up:', galleryId);
    };

    //console.log('Image gallery initialized:', galleryId);
}

// Add cleanup when removing markers
function removeMarker(marker) {
    if (marker.classObserver) {
        marker.classObserver.disconnect();
    }
    marker.map = null;
}