// vibes.js

let vibeData = []; // Store vibe data globally to access it when markers are clicked


// Update the filterMarkersByDateRange function to just fetch vibes
function filterMarkersByDateRange(rangeValue) {
    $("#rangeValue").text(rangeValue);
    fetchVibesAndEventsForVisibleVenueMarkers();
}

// Function to display vibe details in the bottom sheet
function showVibeDetails(vibeId) {
    const vibe = vibeData.find(v => v.id === vibeId); // Find the vibe from stored data
    if (vibe) {
        const detailsContent = generateDetailsContent(vibe);
        $('#vibeDetails').html(detailsContent);
        // setIsSheetShown($('#vibeSheet'), true);
    } else {
        console.error("Vibe not found for ID:", vibeId);
    }
}

// Function to generate HTML content for the bottom sheet
function generateDetailsContent(data) {
    return `
        <strong>Name:</strong> ${data.name || 'Not available'}<br/>
        <strong>Marker ID:</strong> ${data.id || 'Not available'}<br/>
        <strong>Latitude:</strong> ${data.latitude || 'Not available'}<br/>
        <strong>Longitude:</strong> ${data.longitude || 'Not available'}<br/>
        <strong>Liked:</strong> ${data.liked !== null ? (data.liked ? 'Yes' : 'No') : 'No feedback'}<br/>
        ${data.detailsLink ? `<a href="${data.detailsLink}" target="_blank">More details</a>` : ''}
    `;
}

//perhaps by throttling or debouncing the AJAX calls.
//Ensure that the place_id is present in the metadata for each marker.
// You might need to adjust the way vibe counts are displayed based on how the DOM elements are structured.
function fetchVibes(placeId) {
    console.log('Fetching vibes for place ID:', placeId); // Debugging line

    $.ajax({
        type: 'GET',
        url: 'get_vibe.php', // Endpoint to get vibe data
        data: { place_id: placeId },
        dataType: 'json',
        success: function (response) {
            console.log('Vibe data fetched:', response); // Debugging line

            if (response.success) {
                const likes = response.likes_count;
                const dislikes = response.dislikes_count;

                // Update the HTML with likes and dislikes
                $(`#like-count-${placeId}`).text(`Likes: ${likes}`);
                $(`#dislike-count-${placeId}`).text(`Dislikes: ${dislikes}`);
            } else {
                console.error('Failed to fetch vibe data:', response.error_message);
            }
        },
        error: function (xhr, status, error) {
            console.error('Error fetching vibe data:', error);
            console.error('XHR:', xhr); // Log the full XHR object for more details
        }
    });
}

// Create a debounced version of fetchVibesForVisibleMarkers
// const debouncedFetchVibes = debounce(fetchVibesForVisibleVenueMarkers, 5000);
const debouncedFetchVibes = debounce(fetchVibesAndEventsForVisibleVenueMarkers, 5000);

// Modified function to fetch events for visible markers
function fetchVibesAndEventsForVisibleVenueMarkers() {
    return new Promise((resolve, reject) => {
        const visibleVenueMarkers = markers.filter(marker =>
            marker.metadata?.type === 'venue' &&
            map.getBounds().contains(marker.position)
        );

        const placeIds = visibleVenueMarkers.map(marker => marker.metadata.place_id);

        if (placeIds.length === 0) {
            resolve();
            return;
        }

        $('.fetching-vibes-loader').addClass('show');

        // Create separate promises for vibes and events
        const vibesPromise = fetchVibesForMarkers(visibleVenueMarkers, placeIds);
        const eventsPromise = fetchEventsForMarkers(visibleVenueMarkers, placeIds);

        Promise.all([vibesPromise, eventsPromise])
            .then(() => {
                $('.fetching-vibes-loader').removeClass('show');
                resolve();
            })
            .catch(error => {
                $('.fetching-vibes-loader').removeClass('show');
                console.error('Error fetching data:', error);
                reject(error);
            });
    });
}

// Add this function to vibes.js
function updateMarkersWithVibes(visibleVenueMarkers, vibeResponse) {
    if (!vibeResponse || !vibeResponse.vibe_counts) {
        console.warn('No vibe data to process');
        return;
    }

    visibleVenueMarkers.forEach(marker => {
        if (!marker.metadata?.place_id) return;

        const vibes = vibeResponse.vibe_counts[marker.metadata.place_id];
        if (vibes) {
            const score = vibes.likes_count - vibes.dislikes_count;
            marker.metadata.vibeScore = score;
            marker.metadata.likes = vibes.likes_count || 0;
            marker.metadata.dislikes = vibes.dislikes_count || 0;

            const vibeClass = score > 0 ? 'positive' : score < 0 ? 'negative' : 'neutral';
            // In updateMarkersWithVibes
            updateMarkerClasses(marker, [vibeClass], 'vibes');
        }
    });
}

// Update the fetchVibesForMarkers function to properly handle the response
function fetchVibesForMarkers(visibleVenueMarkers, placeIds) {
    return new Promise((resolve, reject) => {
        $.ajax({
            type: 'POST',
            url: 'get_vibe.php',
            data: { place_ids: JSON.stringify(placeIds) },
            dataType: 'json',
            success: function (vibeResponse) {
                if (vibeResponse.success) {
                    updateMarkersWithVibes(visibleVenueMarkers, vibeResponse);
                } else {
                    console.warn('Failed to fetch vibe data:', vibeResponse.error_message);
                }
                resolve(); // Always resolve even if there's no data
            },
            error: function (xhr, status, error) {
                console.error('Error fetching vibes:', error);
                reject(error);
            }
        });
    });
}

// Separate function to handle events fetching
function fetchEventsForMarkers(visibleVenueMarkers, placeIds) {
    return new Promise((resolve, reject) => {
        // Show loading state
        const eventsUl = $('#eventsUl');
        eventsUl.empty().append('<div class="loading-data">Loading events...</div>');

        $.ajax({
            type: 'GET',
            url: 'get_events.php',
            data: { place_ids: JSON.stringify(placeIds) },
            dataType: 'json',
            success: function (eventResponse) {
                if (eventResponse?.events) {
                    // Store global events
                    window.globalEvents = eventResponse.events;
                    
                    // Update events list
                    populateEventsList(eventResponse.events);
                    
                    // Update markers
                    visibleVenueMarkers.forEach(marker => {
                        updateMarkerWithEvents(marker, eventResponse.events);
                    });
                } else {
                    eventsUl.empty().append('<div class="no-results"><p>No events found for this area. We\'re working on it!</p></div>');
                }
                resolve();
            },
            error: function (xhr, status, error) {
                console.error('Error fetching events:', error);
                eventsUl.empty().append('<div class="no-results"><p>Error loading events</p></div>');
                reject(error);
            }
        });
    });
}

function updateMarkerWithEvents(marker, events) {
    if (!marker.metadata?.place_id) return;

    const placeId = marker.metadata.place_id;
    const today = new Date().toISOString().split('T')[0];

    const todayEvents = events.filter(event =>
        event.place_id === placeId && event.date_from === today
    );

    if (todayEvents.length > 0) {
        const newClasses = ['event'];

        // Add category class if available
        const eventWithCategory = todayEvents.find(event => event.category);
        if (eventWithCategory?.category) {
            const categoryClass = eventWithCategory.category.toLowerCase().replace(/[^a-z0-9]/g, '_');
            newClasses.push(categoryClass);
        }

        updateMarkerClasses(marker, newClasses);
    }
}

// Helper function to update marker with events
// Utility function to update marker classes
function updateMarkerClasses(marker, newClasses) {
    if (!marker || !marker.content) {
        console.warn('Invalid marker or marker content');
        return;
    }

    console.log('Updating marker classes:', {
        placeId: marker.metadata?.place_id,
        currentClasses: marker.content.className.split(' '),
        newClasses: newClasses,
        caller: new Error().stack
    });

    // Define class categories and their possible values
    const classCategories = {
        base: ['venue-marker'],
        vibe: ['positive', 'negative', 'neutral'],
        event: ['event'],
        category: [] // This will be populated from your event categories
    };

    // Get current classes as an array
    const currentClasses = marker.content.className.split(' ');

    // Keep classes that don't belong to the categories we're updating
    const preservedClasses = currentClasses.filter(className => {
        const isPreserved = !Object.values(classCategories).flat().includes(className);
        if (!isPreserved) {
            //console.log(`Removing class: ${className} from marker ${marker.metadata?.place_id}`);
        }
        return isPreserved;
    });

    // Combine preserved classes with new classes, avoiding duplicates
    const updatedClasses = [...new Set([...preservedClasses, ...newClasses])];

    console.log('Final classes:', {
        placeId: marker.metadata?.place_id,
        preservedClasses,
        updatedClasses
    });

    // Update the marker's className
    marker.content.className = updatedClasses.join(' ');
}

// Define the marker click handler function
function handleMarkerClick() {
    const marker = this; // 'this' refers to the marker that was clicked

    if (marker.metadata.type === 'event') {
        const event = events.find(e => e.id === marker.metadata.id);
        if (event) {
            showEventDetails(event);
        } else {
            console.error('Event not found for marker:', marker.metadata.id);
        }
    } else if (marker.metadata.type === 'vibe') {
        showVibeDetails(marker.metadata.id);
    } else if (marker.metadata.type === 'venue') {
        $.ajax({
            type: 'GET',
            url: 'get_venue_details.php',
            data: {
                place_id: marker.metadata.place_id,
                cache_duration: getCacheDurationInSeconds()
            },
            dataType: 'json',
            success: function (response) {
                if (response.success && response.data) {
                    console.log('Fetched venue details from DB:', response.data);
                    if (response.data.needs_refresh) {
                        fetchGoogleVenueDetails(marker.metadata.place_id, marker, true);
                    } else {
                        displayVenueDetails(response.data);
                        fetchVibes(marker.metadata.place_id);
                        showVenueSheet();
                    }
                } else {
                    console.warn('Venue not found in DB, fetching from Google API');
                    fetchGoogleVenueDetails(marker.metadata.place_id, marker, true);
                }
            },
            error: function (xhr, status, error) {
                console.error('Error fetching venue details from DB:', error);
                fetchGoogleVenueDetails(marker.metadata.place_id, marker, true);
            }
        });
    } else {
        console.warn('Unknown marker type:', marker.metadata.type);
    }
}



// Enhanced monitoring function
// function monitorMarkerClasses(marker) {
//     if (!marker || !marker.content) return;

//     const observer = new MutationObserver((mutations) => {
//         mutations.forEach((mutation) => {
//             if (mutation.attributeName === 'class') {
//                 const oldClasses = mutation.oldValue || '';
//                 const newClasses = marker.content.className;

//                 console.log('Marker class change detected:', {
//                     timestamp: new Date().toISOString(),
//                     placeId: marker.metadata?.place_id,
//                     oldClasses: oldClasses.split(' '),
//                     newClasses: newClasses.split(' '),
//                     removedClasses: oldClasses.split(' ').filter(c => !newClasses.includes(c)),
//                     addedClasses: newClasses.split(' ').filter(c => !oldClasses.includes(c)),
//                     stack: new Error().stack.split('\n').slice(1, 4).join('\n'), // Show only relevant stack frames
//                     metadata: {
//                         vibeScore: marker.metadata?.vibeScore,
//                         hasEvents: marker.metadata?.hasEvents,
//                         eventCategory: marker.metadata?.eventCategory
//                     }
//                 });
//             }
//         });
//     });

//     observer.observe(marker.content, {
//         attributes: true,
//         attributeOldValue: true,
//         attributeFilter: ['class']
//     });

//     marker.classObserver = observer;
// }

// Enhanced updateMarkerClasses function
function updateMarkerClasses(marker, newClasses, source = '') {
    if (!marker || !marker.content) {
        console.warn('Invalid marker or marker content');
        return;
    }

    // console.log('Updating marker classes:', {
    //     timestamp: new Date().toISOString(),
    //     source: source,
    //     placeId: marker.metadata?.place_id,
    //     currentClasses: marker.content.className.split(' '),
    //     newClasses: newClasses,
    //     stack: new Error().stack.split('\n').slice(1, 4).join('\n')
    // });

    // Get current classes as an array
    const currentClasses = marker.content.className.split(' ');

    // Always keep 'venue-marker'
    const updatedClasses = new Set(['venue-marker']);

    // Add existing classes that we want to keep
    currentClasses.forEach(cls => {
        if (cls !== 'venue-marker') {
            updatedClasses.add(cls);
        }
    });

    // Add new classes
    newClasses.forEach(cls => updatedClasses.add(cls));

    // Convert Set back to string
    const finalClasses = Array.from(updatedClasses).join(' ');

    // console.log('Final marker state:', {
    //     placeId: marker.metadata?.place_id,
    //     finalClasses: finalClasses,
    //     source: source
    // });

    // Update the marker's className
    marker.content.className = finalClasses;
}