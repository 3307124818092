jQuery(document).ready(function($) { // var rangeValue = $("#dateRange").val();


    $(".filter-widget").on("click", function() {
        $(this).toggleClass('active');
        $(".filters").slideToggle();

    });

    // // Initialize the slider
    // const rangeValue = parseInt($("#date-range-slider").val(), 10);
    // filterMarkersByDateRange(rangeValue);

    // // Debounced function for filtering markers
    // const debouncedFilterMarkers = debounce((value) => {
    //     filterMarkersByDateRange(value);
    // }, 300); // Adjust the debounce delay as needed (300ms in this example)

    // $("#date-range-slider").on("input", function () {
    //     console.log("Slider value changed");
    //     debouncedFilterMarkers($(this).val());
    // });



    $("input[name='likeStatus']").on('change', function(event) {
        let selectedValue = $(this).val();

        if (selectedValue == '1') {
            $("#good-vibes-selected").slideDown();
        } else {
            $("#good-vibes-selected").slideUp();
        }

        // Always show the submit section when a choice is made
        $("#submit-section").show();

        // Enable the submit button
        $("#submit-location").prop("disabled", false);
    });



    function getSelectedVenue() {
        const dropdown = document.getElementById('nearby-bars-dropdown');
        const selectedOption = dropdown.options[dropdown.selectedIndex];

        // Handle unknown venue case
        const isUnknownVenue = selectedOption.value === 'unknown' || !selectedOption.value;

        return {
            placeId: isUnknownVenue ? null : selectedOption.value,
            name: isUnknownVenue ? 'Unknown Venue' : selectedOption.text
        };
    }

    // Modified submit_location event handler in app.js
    // Update the submit handler in app.js
    $("#submit-location").on("click", function(event) {
        event.preventDefault();

        var liked = Number($("input[name='likeStatus']:checked").val());
        var music = $("#answer-music").prop("checked") ? 1 : 0;
        var drinks = $("#answer-drinks").prop("checked") ? 1 : 0;

        const selectedVenue = getSelectedVenue();

        var postData = {
            'latitude': userLocation.lat,
            'longitude': userLocation.lng,
            'liked': liked,
            'music': music,
            'drinks': drinks,
            'place_id': selectedVenue.placeId,
            'venue_name': selectedVenue.name
        };

        $.ajax({
            type: "POST",
            url: "submit_location.php",
            data: postData,
            dataType: "json",
            success: function(response) {
                if (response.success) {
                    const marker = markers.find(m => m.metadata ? .place_id === selectedVenue.placeId);
                    if (marker) {
                        // Update the marker's metadata
                        marker.metadata.likes = (marker.metadata.likes || 0) + (liked === 1 ? 1 : 0);
                        marker.metadata.dislikes = (marker.metadata.dislikes || 0) + (liked === 0 ? 1 : 0);
                        marker.metadata.vibeScore = marker.metadata.likes - marker.metadata.dislikes;

                        // update classes
                        marker.content.className = marker.content.className
                            .split(' ')
                            .filter(className => !['positive', 'negative', 'neutral'].includes(className))
                            .join(' ');

                        const score = marker.metadata.vibeScore;
                        if (score > 0) {
                            marker.content.classList.add('positive');
                        } else if (score < 0) {
                            marker.content.classList.add('negative');
                        } else {
                            marker.content.classList.add('neutral');
                        }
                    }

                    fetchVibesAndEventsForVisibleVenueMarkers();

                    Toastify({
                        text: "💥 Boom! Vibes added ",
                        duration: 5000,
                        close: true,
                        gravity: "top",
                        position: "right",
                        stopOnFocus: true,
                        style: {
                            background: "linear-gradient(to right, #00b09b, #96c93d)"
                        },
                    }).showToast();

                    $("#location-form")[0].reset();
                    $("#good-vibes-selected").hide();
                } else {
                    alert("There was an error adding your location.");
                }
            },
            error: function(jqXHR, textStatus, errorThrown) {
                alert("There was an error submitting the form.");
            }
        });
    });


    // Add event listeners to the venue sheet vibe buttons
    // Add event listeners to the venue sheet vibe buttons
    document.querySelectorAll('#venueSheet input[name="likeStatus"]').forEach(radio => {
        radio.addEventListener('change', function() {
            const selectedVibe = this.value;
            const venueName = document.getElementById('venue-sheet-venue-name').textContent.trim();
            const venueSheet = document.getElementById('venueSheet');

            // Get the venue's place_id from the current marker or venue data
            const placeId = venueSheet.getAttribute('data-place-id');

            // Close the venue sheet
            closeSheet(venueSheet);

            // Show the add vibe sheet
            const addVibeSheet = document.getElementById('sheet-add');
            showSheet(addVibeSheet);

            // Set the selected vibe in the form and trigger the change event properly
            const targetRadio = document.querySelector(`#location-form input[name="likeStatus"][value="${selectedVibe}"]`);
            if (targetRadio) {
                targetRadio.checked = true;
                // Trigger jQuery change event to ensure the slideDown/slideUp animations work
                $(targetRadio).trigger('change');
            }

            // Select the venue in the dropdown
            const dropdown = document.getElementById('nearby-bars-dropdown');
            if (dropdown) {
                // First ensure the option exists
                let venueOption = Array.from(dropdown.options).find(option => option.value === placeId);
                if (!venueOption) {
                    // If the option doesn't exist, add it
                    venueOption = new Option(venueName, placeId);
                    dropdown.add(venueOption);
                }
                dropdown.value = placeId;
            }
        });
    });

});

// Function to update an existing venue marker based on place_id and liked status
function updateVenueMarker(placeId, liked) {
    // Find the marker by placeId and update its properties
    const marker = markers.find(marker => marker.metadata && marker.metadata.place_id === placeId);
    if (marker) {
        // Update marker's metadata if needed
        marker.metadata.liked = liked;

        // Apply any other necessary updates (e.g., icon change, class change, etc.)
        // updateMarkerAppearance(marker);
    }
}

// change view using bottom nav
function switchView(targetId, clickedNavItem) {
    // Close any open sheets
    closeAllSheets();

    // Hide all content views and remove 'active' class
    document.querySelectorAll('.content-view').forEach(view => {
        view.setAttribute('aria-hidden', 'true');
        view.classList.remove('active');
    });

    // Show the relevant content view and add 'active' class
    const targetView = document.getElementById(targetId);
    if (targetView) {
        targetView.setAttribute('aria-hidden', 'false');
        targetView.classList.add('active');
    }

    // Update active state of nav items
    document.querySelectorAll('#primary-nav li').forEach(item => {
        item.classList.toggle('active', item === clickedNavItem);
    });

    // If clicked item has 'modal-target' attribute, open the corresponding sheet
    const modalTargetId = clickedNavItem.getAttribute('modal-target');
    if (modalTargetId) {
        const targetSheet = document.getElementById(modalTargetId);
        if (targetSheet) {
            showSheet(targetSheet);
        }
    }
}

// Add event listeners to nav items
document.querySelectorAll('#primary-nav li').forEach(navItem => {
    navItem.addEventListener('click', function() {
        const targetId = this.getAttribute('content-target');
        switchView(targetId, this);
    });
});

// Call switchView to set the initial state (optional)
switchView('content-map', document.getElementById('nav-map'));


// accordion
// Select all accordion triggers

/* JavaScript */
document.addEventListener('DOMContentLoaded', () => {
    const accordionTriggers = document.querySelectorAll('.accordion .trigger');

    accordionTriggers.forEach(trigger => {
        trigger.addEventListener('click', function() {
            const targetId = this.getAttribute('data-target');
            const targetContent = document.getElementById(targetId);

            this.classList.toggle('active');

            if (targetContent) {
                targetContent.classList.toggle('show');

                if (targetContent.classList.contains('show')) {
                    const customMaxHeight = targetContent.getAttribute('data-mh');
                    if (customMaxHeight) {
                        targetContent.style.maxHeight = customMaxHeight;
                    } else {
                        targetContent.style.maxHeight = targetContent.scrollHeight + 'px';
                    }
                } else {
                    targetContent.style.maxHeight = null;
                }
            }
        });
    });
});



// Event listener for the "Set Location" button
// Check if the URL contains '?debug'
// Add this function to update everything when new location is set
function updateDebugLocation(newLocation) {
    // console.log('Debug mode: Setting location to', newLocation);

    // Update user location
    userLocation = newLocation;

    // Clear existing markers except user marker
    markers.forEach(marker => {
        if (marker !== userMarker) {
            marker.map = null;
        }
    });
    markers = markers.filter(marker => marker === userMarker);

    // Clear the venues list
    const barsUl = document.getElementById('barsUl');
    if (barsUl) {
        barsUl.innerHTML = '';
    }

    // Update user marker position
    updateUserMarker(newLocation);

    // Center map on new location
    map.setCenter(newLocation);

    // Clear venue cache
    for (let key in localStorage) {
        if (key.startsWith('nearbyBars_')) {
            localStorage.removeItem(key);
        }
    }

    // Fetch new venues and update map
    fetchBarsNearUser();

    // Reload vibes markers
    loadVibeMarkers();
}

// Modify your existing debug function to use the new update function
function debug() {
    if (window.location.search.includes('debug')) {
        document.getElementById('debug-location').style.display = 'block';

        document.getElementById('set-location-btn').addEventListener('click', function() {
            const lat = parseFloat(document.getElementById('latitude-input').value);
            const lng = parseFloat(document.getElementById('longitude-input').value);

            if (!isNaN(lat) && !isNaN(lng)) {
                updateDebugLocation({ lat, lng });
            } else {
                alert('Please enter valid latitude and longitude values.');
            }
        });
    } else {
        document.getElementById('debug-location').style.display = 'none';
    }
}