let events = [];


// show global events in a list
function populateEventsList(events) {
    const eventsUl = $('#eventsUl');
    eventsUl.empty(); // Clear the list before populating

    if (!events || events.length === 0) {
        eventsUl.append('<div class="no-results"><p>No events found for this area. We\'re working on it!</p></div>');
        return;
    }

    // Create a map of place_ids to venue names
    const venueNameMap = {};
    nearbyBars.forEach(bar => {
        venueNameMap[bar.place_id] = bar.name;
    });

    // Create a map to store the next upcoming event for each place_id
    const nextEventsByVenue = {};

    events.forEach(event => {
        const today = new Date();
        let eventDate = event.date_from ? new Date(event.date_from) : null;

        // If the event has a specific date, use it; otherwise, it's recurring or descriptive
        if (eventDate && eventDate >= today) {
            // If there's already an event for this venue, compare dates
            if (!nextEventsByVenue[event.place_id] || new Date(nextEventsByVenue[event.place_id].date_from) > eventDate) {
                nextEventsByVenue[event.place_id] = event;
            }
        } else if (event.is_recurring || event.raw_date_description) {
            // For recurring or descriptive events, prioritize them if no other future events are found
            if (!nextEventsByVenue[event.place_id]) {
                nextEventsByVenue[event.place_id] = event;
            }
        }
    });

    // Now populate the event list with the next event from each venue
    Object.values(nextEventsByVenue).forEach(event => {
                // Format times without seconds
                const timeFrom = event.time_from ? event.time_from.substring(0, 5) : null;
                const timeTo = event.time_to ? event.time_to.substring(0, 5) : null;

                const venueName = venueNameMap[event.place_id] || 'Unknown Venue';
                const formattedDate = event.date_from ?
                    (event.date_to ?
                        `${formatDate(event.date_from)} - ${formatDate(event.date_to)}` :
                        formatDate(event.date_from)) :
                    (event.raw_date_description || 'Date not available');

                const eventItem = `
    <li class="event-item" ${event.link ? `onclick="window.open('${event.link}', '_blank')"` : ''} style="cursor: ${event.link ? 'pointer' : 'default'}">
        ${event.event_image ? `<figure class="item-img"><img src="${event.event_image}" alt="Event Image"></figure>` : ''}
        <div class="item-content">
            <div class="event-date">${formattedDate} - 
            ${timeFrom ? `<span class="event-time">${timeFrom}${timeTo ? ` - ${timeTo}` : ''}</span></div>` : ''}
            <h4 class="item-name">${event.event_name}</h4>
            <div class="venue-name">${venueName}</div>
            ${event.description ? `<div class="event-description">${event.description}</div>` : ''}
        </div>
    </li>
        `;
        eventsUl.append(eventItem);
    });
}

// Helper function to format dates (e.g., "2024-09-13" -> "13 Sep 2024")
function formatDate(dateString) {
    if (!dateString) return 'Date not available';
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return 'Invalid date';
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
}

// Helper function to fetch events for the venue and populate the event info
function fetchEventsForVenue(place_id, eventInfoElement) {
    $.ajax({
        type: 'GET',
        url: 'get_events.php',
        data: { place_ids: JSON.stringify([place_id]) },
        dataType: 'json',
        success: function (eventResponse) {
            if (eventResponse && eventResponse.events && Array.isArray(eventResponse.events)) {
                // Deduplicate events based on name, date, and time
                const uniqueEvents = eventResponse.events.reduce((acc, event) => {
                    const eventKey = `${event.event_name}_${event.date_from}_${event.time_from}_${event.time_to}`;
                    if (!acc[eventKey]) {
                        acc[eventKey] = event;
                    }
                    return acc;
                }, {});

                const events = Object.values(uniqueEvents).sort((a, b) => {
                    if (!a.date_from) return 1;
                    if (!b.date_from) return -1;
                    return new Date(a.date_from) - new Date(b.date_from);
                });

                const nextEvent = events.length > 0 ? events[0] : null;
                const today = new Date().toISOString().split('T')[0];

                // Update marker if needed
                if (nextEvent) {
                    const isEventToday = nextEvent.date_from === today;
                    const nextEventCategory = isEventToday && nextEvent.category ?
                        nextEvent.category.toLowerCase().replace(/[^a-z0-9]/g, '_') : null;

                    markers.forEach(marker => {
                        if (marker.metadata?.place_id === place_id) {
                            const newClasses = ['event'];
                            if (nextEventCategory) {
                                newClasses.push(nextEventCategory);
                            }
                            updateMarkerClasses(marker, newClasses, 'events');
                        }
                    });
                }

                // Populate event info element
                if (eventInfoElement) {
                    let eventContent = '';
                    events.forEach(event => {
                        const timeFrom = event.time_from ? event.time_from.substring(0, 5) : null;
                        const timeTo = event.time_to ? event.time_to.substring(0, 5) : null;

                        const formattedDate = event.date_from
                            ? (event.date_to
                                ? `${formatDate(event.date_from)} - ${formatDate(event.date_to)} `
                                : formatDate(event.date_from))
                            : (event.raw_date_description || 'Date not available');

                        const cardItem = `
                            <div class="card-item" ${event.link ? `onclick="window.open('${event.link}', '_blank')"` : ''} style="cursor: ${event.link ? 'pointer' : 'default'}">
                                ${event.event_image ? `<figure class="item-img"><img src="${event.event_image}" alt="Event Image"></figure>` : ''}
                                <div class="item-content">
                                    <div class="event-date">${formattedDate}${timeFrom ? ` -
                                    <span class="event-time">${timeFrom}${timeTo ? ` - ${timeTo}` : ''}</span>` : ''}</div>
                                    <h4 class="item-name">${event.event_name || 'Event Name Not Available'}</h4>
                                    ${event.description ? `<div class="event-description">${event.description}</div>` : ''}
                                </div>
                            </div>`;
                        eventContent += cardItem;
                    });

                    eventInfoElement.innerHTML = eventContent;
                    eventInfoElement.style.display = 'flex';
                }
            } else {
                if (eventInfoElement) {
                    eventInfoElement.innerHTML = '<div class="no-results"><p>No events found</p></div>';
                    eventInfoElement.style.display = 'flex';
                }
            }
        },
        error: function (xhr, status, error) {
            console.error('AJAX error:', {
                status: status,
                error: error,
                response: xhr.responseText
            });
        }
    });
}